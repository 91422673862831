<template>
  <div class="no-auth-board no-auth-board-wrapper" @click.stop="close">
    <div class="no-auth-board_panel">
      <i class="icon-guanbi btn-close" @click.stop="close"></i>
      <span>{{ message }}</span>
      <div class="user-wrapper">
        <div class="user-info" v-for="(item, index) in admins">
          <user-avatar
            :name="item.user.name"
            :src="item.user.avatar"
            width="32"
          ></user-avatar>
          <span class="line1">{{ item.user.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ModuleRoleApi } from "@/api/api";

export default {
  components: {},
  data() {
    return {
      message: "",
      admins: [],
    };
  },

  methods: {
    show(tips, module, showSuperAdmin = true) {
      this.message = tips;
      this.loadData(module, showSuperAdmin);
    },

    close() {
      // this.$el.remove()
      this.$el.parentNode.removeChild(this.$el);
    },

    loadData(module, showSuperAdmin = true) {
      ModuleRoleApi.getAdmins({
        module: module,
        containSuperAdmin: showSuperAdmin ? 1 : 0,
      })
        .then((resp) => {
          this.admins = resp.data || [];
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="scss">
.no-auth-board-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  .no-auth-board_panel {
    width: 240px;
    padding: 40px 24px 24px 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #20202096;
    border-radius: 5px;
    position: relative;
    font-size: 14px;
    color: white;

    .btn-close {
      color: white;
      position: absolute;
      font-size: 14px;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }

    .user-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      font-size: 13px;
      margin-top: 8px;

      .user-info {
        width: 33.3333%;
        padding: 8px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;

        .user-avatar {
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>
