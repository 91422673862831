// index.js
import index from "./index.vue";
import Vue from "vue";
//通过extend构造器 创建一个‘子类’
const NoAuthBoard = Vue.extend(index);
//创建shmily的实例  并且挂载到元素上
const noAuthBoard = new NoAuthBoard();
let init = () => {
  noAuthBoard.$mount();
  document.body.appendChild(noAuthBoard.$el);
};
let caller = (tips, module, showAdmin = true) => {
  init();
  noAuthBoard.show(tips, module, showAdmin);
};
export default {
  //main.js中  Vue.use会自己调用install来完成  让$shmily 指向 caller函数，并且接受参数传递给组件里面的createMoon 函数
  install(Vue) {
    Vue.prototype.$noAuthBoardTip = caller;
  },
};
