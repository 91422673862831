<!--  -->
<template>
  <div class="component-back-bar_wrap">
    <div class="btn-back" @click="$router.back()">
      <i class="el-icon-arrow-left back-bar_back-btn"></i>
      <span>back</span>
    </div>
    <div v-if="$slots.default" class="component-back-bar_slot">
      <slot></slot>
    </div>
    <div
      v-else-if="(title || '').trim().length > 0"
      class="component-back-bar_title"
    >
      <span>{{ title }}</span>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  props: ["title"],
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
.component-back-bar_wrap {
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  display: flex;
  align-items: center;

  .btn-back {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    .back-bar_back-btn {
      font-size: 16px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      flex-shrink: 0;
    }
  }

  .component-back-bar_slot {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .component-back-bar_title {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
}
</style>
