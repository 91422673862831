var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'user-avatar',
    'user-avatar-wrapper',
    _vm.circle ? 'circle' : '',
    _vm.border ? 'border' : '' ],style:({ width: _vm.width + 'px', height: _vm.width + 'px' })},[((_vm.src || '').length > 0)?_c('el-image',{staticClass:"avatar-img",attrs:{"src":_vm.src,"alt":"","fit":"cover"},on:{"error":function($event){_vm.src = null}}}):_c('div',{staticClass:"avatar-text",style:({
      backgroundColor: _vm.background,
      color: _vm.color,
      fontSize: _vm.size + 'px',
    })},[_vm._v(" "+_vm._s((_vm.name || "").substring(0, 2))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }