import { tableData } from "./table-mock-data";

import Cookies from "js-cookie";
// 加密解密
let Base64 = require("js-base64").Base64;

const TaskStatusMap = {
  0: "todo",
  1: "progress",
  2: "completed",
  "-1": "overdue",
};

export const StudentFilterMenus = [
  { key: "reports", title: "问卷筛选" },
  { key: "clazz", title: "年级班级" },
  { key: "gender", title: "性别" },
  { key: "range", title: "身份" },
  { key: "country", title: "国籍" },
  { key: "age", title: "年龄段" },
  { key: "join_time", title: "入园时间" },
  // { key: 'addr', title: '家校距离' },
  { key: "tag", title: "标签/分组" },
];

export const StudentTableColumn = [
  { key: "gender", title: "性别", icon: "icon-xingbie" },
  { key: "age", title: "年龄", icon: "icon-nianling1" },
  { key: "guardian", title: "监护人", icon: "icon-lianxifangshi1" },
  { key: "addr", title: "住址", icon: "icon-juli1" },
];

export const StudentRange = [
  { label: "本区", value: 1 },
  { label: "本市", value: 2 },
  { label: "外省市", value: 3 },
  { label: "港澳台", value: 4 },
  { label: "外籍", value: 5 },
  { label: "其他", value: 0 },
];

export const StudentCardType = [
  { label: "身份证", value: "1" },
  { label: "护照", value: "2" },
];

export const ParentIdentity = [
  { label: "爸爸", value: "爸爸" },
  { label: "妈妈", value: "妈妈" },
  { label: "外公", value: "外公" },
  { label: "外婆", value: "外婆" },
  { label: "爷爷", value: "爷爷" },
  { label: "奶奶", value: "奶奶" },
  { label: "其他", value: "其他" },
];

export const AttendanceTypes = [
  { value: "HOLS_NIAN", label: "年假" },
  { value: "HOLS_DXB", label: "带薪病假" },
  { value: "HOLS_ZG", label: "主管假" },
  { value: "HOLS_GL", label: "工龄假" },
  { value: "HOLS_OTHER", label: "特殊假" },
  { value: "HOLS_TX", label: "调休" },
  { value: "HOLS_LC", label: "路程假" },
  { value: "HOLS_SHI", label: "事假" },
  { value: "HOLS_BING", label: "病假" },
  { value: "HOLS_CHAN", label: "产假" },
  { value: "HOLS_HUN", label: "婚假" },
  { value: "HOLS_SANG", label: "丧假" },
  { value: "HOLS_GONG", label: "公出" },
  { value: "ATTENDANCE_CD", label: "迟到" },
  { value: "ATTENDANCE_ZT", label: "早退" },
  { value: "ATTENDANCE_JIA", label: "加班" },
  { value: "ATTENDANCE_KG", label: "旷工" },
]
export const HasBalanceHoliays = ['HOLS_NIAN', 'HOLS_DXB', 'HOLS_ZG']


export const WorkTypes = [
  { value: "FULL_TIME", label: "全职" },
  { value: "PART_TIME", label: "兼职" },
  { value: "PROBATION", label: "试用" },
  { value: "PRACTICE", label: "实习" },
  { value: "RETIRED", label: "退休" }
]


export const EducationalTypes = [
  { value: "HIGH_SCHOOL_EDUCATION", label: "高中及以下" },
  { value: "JUNIOR_EDUCATION", label: "大学专科" },
  { value: "UNDERGRADUATE_EDUCATION", label: "大学本科" },
  { value: "MASTER_EDUCATION", label: "硕士及以上" }
]

export const SkillLevels = [
  { value: 'FLUENT', label: '流利' },
  { value: 'ORDINARY', label: '普通' },
  { value: 'SLIGHTLY', label: '略懂' },
]

// 政治面貌  CROWD#群众  PARTY#党员  GROUP#团员
// 户籍    TOWN#城镇  VILLAGE#农村
// 婚姻状况  UNMARRIED#未婚   MARRIED#已婚  DIVORCE#离异



let map2Target = (source, key, value) => {
  let target = {};
  let temp = (source || []).map((item) => {
    if (item[key] == value) {
      target = item;
      return target;
    }
  });
  return target;
};

export const sys_def = {
  tableData: tableData,
  taskStatusMap: TaskStatusMap,
  StudentRange: StudentRange,
  StudentCardType: StudentCardType,
  ParentIdentity: ParentIdentity,
  
  AttendanceTypes: AttendanceTypes,
  HasBalanceHoliays: HasBalanceHoliays,

  WorkTypes: WorkTypes,
  EducationalTypes: EducationalTypes,
  SkillLevels: SkillLevels,


  map2StudentRange(value) {
    return map2Target(StudentRange, "value", value);
  },
  map2StudentCardType(value) {
    return map2Target(StudentCardType, "value", value);
  },
  map2Attendanceype(value) {
    return map2Target(AttendanceTypes, "value", value);
  },

  map2WorkType(value) {
    return map2Target(WorkTypes, "value", value);
  },

  map(source, value, key = 'value') {

    let data = this[source] || []
    return map2Target(data, key, value);
  }
};
